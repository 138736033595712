import React from "react";
import beveragesCategoriesBuilder from "../../../utils/beveragesCategoriesBuilder";
import MenuDrink from "../../menu/Drink";
import ListDrink from "../../list/Drink";
import ListBeverage from "../../list/Beverage";
import MenuBeverage from "../../menu/Beverage";
import SearchDrink from "../SearchDrink";

export default function BeveragesContent(props) {
    const { beverages, isSearching, result, hasOrders, setResult, setIsSearching } = props;

    return (
        <div>
            <div className="block w-full m-auto px-4">
                <SearchDrink
                    className="outline-none w-full my-2 p-2 rounded-lg border text-black dark:text-white"
                    categories={beverages}
                    setResult={setResult}
                    setIsSearching={setIsSearching}></SearchDrink>
            </div>

            <div className="beverages">
                {isSearching ?
                    result.map((category, index) => category.drinks.length > 0 ?
                        <div key={`beverage-cat-${index}`}>
                            <p className="px-4 py-2 uppercase font-bold">{category.name}</p>

                            {category.drinks.map((drink, j) => hasOrders ?
                                <ListDrink drink={drink} key={`beverage-drink-${j}`}></ListDrink> :
                                <MenuDrink drink={drink} key={`beverage-drink-${j}`}></MenuDrink>)}
                        </div> : null
                    ) :
                    beveragesCategoriesBuilder.buildCategoriesTree(beverages).map((beverage, index) => hasOrders ?
                        <ListBeverage beverage={beverage} index={index} key={`beverage-${index}`}></ListBeverage> :
                        <MenuBeverage beverage={beverage} index={index} key={`beverage-${index}`}></MenuBeverage>
                    )}
            </div>
        </div>
    );
}
