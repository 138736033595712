import React from 'react';
import clsx from 'clsx';

export default function DishVariations(props) {
    const { product, changed, setChanged } = props;

    const [isChecked, setIsChecked] = React.useState(false);

    React.useEffect(() => {
        if (product.use_variation) {
            delete product.use_variation;
        }
    }, [product]);

    function onClickHandler(e) {
        if (!isChecked) {
            product.use_variation = {
                "id": product.variations[e.target.attributes[1].value].id,
                "name": product.variations[e.target.attributes[1].value].name,
                "price": product.variations[e.target.attributes[1].value].price,
            };
        } else {
            delete product.use_variation;
        }

        let val = e.target.attributes[1].value;
        setChanged({ [val]: !(isChecked) });

        setIsChecked(!(isChecked));
    }

    return (
        <div className="dish-variations pt-3">
            <div className="p-3">
                <p className="">Che variante vuoi ordinare?</p>
                {product.variations.map((variation, index) =>
                    <div key={index} className={"flex items-center mt-4 rounded-lg bg-grey-200 border-solid border-l-8" + clsx({ " border-mycia-green": Boolean(changed[index]), "border-solid border-l-8 border-transparent": !changed[index] })}>
                        <div className="flex-1">
                            <input type="radio" variantindex={index} id={`variants${index}`} name={`variants${index}`} className="hidden" onClick={onClickHandler} checked={isChecked} />

                            <label className={clsx(["cursor-pointer select-none block p-4", { "font-bold": changed[index] }])} htmlFor={`variants${index}`}>{variation.name}</label>

                        </div>
                        {(Number(variation.price) !== 0) && <label className="py-4 px-3" htmlFor="variants">€ {variation.price}</label>}
                    </div>
                )}
            </div>

        </div>
    );
}
