import { IonSpinner } from "@ionic/react";
import React from "react";
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng
} from "react-places-autocomplete";
import formats from "../../utils/formats";

export default function AutocompleteStreets(props) {
    const { address, setAddress, setCoordinates, focusHandler, setAddressComponents } = props;

    const handleSelect = async value => {
        const results = await geocodeByAddress(value);
        const latLng = await getLatLng(results[0]);
        setAddress(results[0].formatted_address);
        setCoordinates(latLng);
        const formattedAddress = await formats.formatGmapsStreet(results[0].address_components, latLng);
        setAddressComponents(formattedAddress);
    };

    const searchOptions = {
        componentRestrictions: { country: ['it'] },
    };

    return (
        <div>
            <PlacesAutocomplete
                value={address}
                onChange={setAddress}
                onSelect={handleSelect}
                searchOptions={searchOptions}
            >
                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                    <div>
                        <input type="text" name="street" id="street" className="w-full mt-2 py-3 px-4 rounded-lg bg-grey-200" placeholder="Inserisci un indirizzo valido" required {...getInputProps()} onBlur={focusHandler} />

                        <div>
                            {loading ? <div><IonSpinner name="crescent" /></div> : null}

                            {suggestions.map(suggestion => {
                                const style = {
                                    paddingTop: "1em",
                                    backgroundColor: suggestion.active ? "#eb6440" : "#fff",
                                };

                                return (
                                    <div {...getSuggestionItemProps(suggestion, { style })}>
                                        {suggestion.description}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                )}
            </PlacesAutocomplete>
        </div>
    );
}
