import React from 'react';
import { IonModal, IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import './TackpayFollowUpModalStyle.css';

export default function TackpayFollowUpModal({ showModal, setShowModal }) {
    function onCloseHandler() {
        setShowModal(false)
    }

    return (
        <IonModal cssClass="tackpay-followUp-modal-trigger" mode="md" isOpen={showModal} onDidDismiss={onCloseHandler}>
            <div className="overflow-auto text-black p-6 relative">
                <button type="button" onClick={onCloseHandler} className="z-10 absolute top-0 right-0 flex items-center justify-center mt-4 mr-4 p-2 rounded-full shadow-lg bg-white">
                    <IonIcon slot="icon-only" icon={close}></IonIcon>
                </button>

                <div className="mt-8 mb-4 text-center">
                    <p className="text-lg mb-3">Sembra che il ristorante non abbia ancora attivato l'opzione per ricevere le mance.</p>
                    <p className="text-base">Se vuoi, puoi farglielo presente, altrimenti lo contatteremo noi!</p>
                </div>
            </div>
        </IonModal>
    );
}
