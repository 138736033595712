import React from 'react';
import preferencesReducer, { defaultState } from '../reducers/preferences-reducer';
import actionsPreferences from '../actions/preferences';

const PreferencesContext = React.createContext();

function usePreferencesContextValue() {
    const [state, setState] = React.useReducer(preferencesReducer, defaultState);

    const { items } = state;

    const add = React.useCallback(item => setState(actionsPreferences.add(item)), []);
    const remove = React.useCallback(id => setState(actionsPreferences.remove(id)), []);

    const value = React.useMemo(() => {
        return {
            items,
            add,
            remove,
        };
    }, [items, add, remove]);

    return value;
}

function PreferencesProvider(props) {
    const value = usePreferencesContextValue();
    return <PreferencesContext.Provider value={value} {...props} />;
}

function usePreferences() {
    const context = React.useContext(PreferencesContext);
    if (!context) {
        throw new Error("usePreferences must be used within a PreferencesProvider");
    }
    return context;
}

export { PreferencesProvider, usePreferences };
