import React from "react";
import * as ReactTabs from "react-tabs";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "../../../utils/contexts/restaurant-context";
import { useCart } from "../../../utils/contexts/cart-context";
import formats from "../../../utils/formats";

import BeveragesContent from "./BeveragesContent";
import Course from "../../menu/Course";
import Preferred from "../../list/Preferred";
import ReviewsTab from "../ReviewsTab";
import Row from "../../list/Row";
import TabsButton from "./TabsButton";
import TastingMenuContent from "./TastingMenuContent";
import categoriesBuilder from "../../../utils/categoriesBuilder";

export default function Tabs(props) {
    const { hasOrders, defaultTab } = props;

    const { restaurant } = useRestaurant();
    const { t } = useTranslation();
    const { add } = useCart();

    const [activeTab, setActiveTab] = React.useState(defaultTab);
    const [isSearching, setIsSearching] = React.useState(false);
    const [result, setResult] = React.useState([]);

    const hasTastingMenu = Boolean(restaurant.tasting_menu.length > 0);
    const hasBeverages = Boolean(restaurant.beverages.length > 0);
    const hasCourses = Boolean(restaurant.courses.length > 0);
    const hasCustomTabs = Boolean(restaurant.tabs.length > 0);

    const totalTabs =
        1 + // RECENSIONI
        restaurant.tasting_menu.length +
        (restaurant.tabs.length > 0 ?
            restaurant.tabs.length :
            (restaurant.courses.length > 0 ? 1 : 0) +
            (restaurant.beverages.length > 0 ? 1 : 0));

    return (
        <ReactTabs.Tabs selectedIndex={activeTab} onSelect={setActiveTab}>
            <ReactTabs.TabList>
                <div className="tabs relative">
                    <div className="bar overflow-x-scroll flex mx-3 py-3">
                        <ReactTabs.Tab key="reviews">
                            <TabsButton tabText={t("tab.button_rate_place")} isActive={activeTab === 0}></TabsButton>
                        </ReactTabs.Tab>

                        {hasTastingMenu ?
                            restaurant.tasting_menu.map((menu, index) => (
                                <ReactTabs.Tab key={`menu-${index}`}>
                                    <TabsButton
                                        tabText={menu.name}
                                        isActive={activeTab === index + 1}></TabsButton>
                                </ReactTabs.Tab>
                            )) : null}

                        {(hasCourses || hasBeverages) ? (
                            <>
                                {hasCustomTabs ? (
                                    restaurant.tabs.map((tab, index) => {
                                        let tabName;
                                        if (tab && tab.trim() !== "") {
                                            tabName = tab;
                                        } else {
                                            switch (index) {
                                                case 0:
                                                    tabName = t("tab.button_1");
                                                    break;
                                                case 1:
                                                    tabName = t("tab.button_2");
                                                    break;
                                                default:
                                                    tabName = "Piatti";
                                                    break;
                                            }
                                        }
                                        return (
                                            <ReactTabs.Tab key={`tab-${index}`}>
                                                <TabsButton
                                                    tabText={tabName}
                                                    isActive={activeTab === index + 1 + restaurant.tasting_menu.length}
                                                />
                                            </ReactTabs.Tab>
                                        );
                                    })
                                ) : (
                                    <>
                                        {hasCourses ? (
                                            <ReactTabs.Tab key="course">
                                                <TabsButton
                                                    tabText={t("tab.button_1")}
                                                    isActive={activeTab === restaurant.tasting_menu.length + 1}
                                                />
                                            </ReactTabs.Tab>
                                        ) : null}
                                        {hasBeverages ? (
                                            <ReactTabs.Tab key="beverage">
                                                <TabsButton
                                                    tabText={t("tab.button_2")}
                                                    isActive={
                                                        activeTab ===
                                                        restaurant.tasting_menu.length +
                                                        (restaurant.courses.length > 0 ? 2 : 1)
                                                    }
                                                />
                                            </ReactTabs.Tab>
                                        ) : null}
                                    </>
                                )}
                            </>
                        ) : null}

                        {formats.shouldDisplayIndecisiTab(restaurant) ?
                            <ReactTabs.Tab key="indecisi">
                                <TabsButton
                                    isActive={activeTab === totalTabs}
                                    tabText={t("tab.button_3")}></TabsButton>
                            </ReactTabs.Tab> : null}
                    </div>
                </div>
            </ReactTabs.TabList>

            <ReactTabs.TabPanel key="panel-reviews">
                <ReviewsTab></ReviewsTab>
            </ReactTabs.TabPanel>

            {hasTastingMenu ?
                restaurant.tasting_menu.map((menu, index) => (
                    <ReactTabs.TabPanel key={`panel-menu-${index}`}>
                        <TastingMenuContent menu={menu} hasOrders={hasOrders} add={add}></TastingMenuContent>
                    </ReactTabs.TabPanel>
                )) : null}

            {hasCourses ?
                <ReactTabs.TabPanel key="panel-courses">
                    {categoriesBuilder.buildCategoriesTree(restaurant.courses).map((course, index) =>
                        hasOrders ?
                            <Row course={course} index={index} key={`course-${index}`}></Row> :
                            <Course course={course} index={index} key={`course-${index}`}></Course>
                    )}
                </ReactTabs.TabPanel> : null}

            {hasBeverages ?
                <ReactTabs.TabPanel key="panel-beverages">
                    <BeveragesContent
                        beverages={restaurant.beverages}
                        isSearching={isSearching}
                        result={result}
                        hasOrders={hasOrders}
                        setResult={setResult}
                        setIsSearching={setIsSearching}></BeveragesContent>
                </ReactTabs.TabPanel> : null}

            <ReactTabs.TabPanel key="panel-preferred">
                <Preferred></Preferred>
            </ReactTabs.TabPanel>
        </ReactTabs.Tabs>
    );
}

