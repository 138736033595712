import React from 'react';
import coursesClient from '../../logic/courses';
import Select from 'react-select/async';
import { useRestaurant } from '../../utils/contexts/restaurant-context';

export default function SearchDish(props) {
    const { className, name, result, setResult } = props;

    const { restaurant } = useRestaurant();

    async function search(e) {
        const target = e.toLowerCase();
        const response = await coursesClient.doGetFilterDishes(restaurant.token, target);
        const filteredResult = response?.filter(
            (item) => !result.some((selected) => selected.pia_id === item.pia_id)
        );

        return filteredResult.map(item => ({ ...item, label: item.pia_nome, value: item.pia_id }));
    };

    return (
        <Select
            className={className}
            classNamePrefix="select"
            styles={{
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    background: "#F2EFEF",
                    border: "none",
                    padding: "0.25rem 0 0.25rem 0",
                    borderRaius: "0.375rem",
                }),
            }}
            cacheOptions
            defaultOptions
            loadOptions={search}
            placeholder="Cerca piatto"
            name={name}
            onChange={option => setResult(prev => [...prev, option])}
        />
    );
}
