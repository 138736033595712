import clsx from "clsx";

export default function TabsButton(props) {
    const { tabText, isActive } = props;

    const classes = clsx(
        "tab cursor-pointer whitespace-nowrap inline-block",
        "mx-2 py-1 px-4",
        "rounded-full border border-solid border-mycia-blue",
        {
            "active tab-active bg-mycia-blue text-white": isActive,
        }
    );

    return <div className={classes}>{tabText}</div>;
}
