import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRestaurant } from "../../utils/contexts/restaurant-context";
import { userCanReview } from "../../utils/reviews";
import ReviewItem from "./ReviewItem";
import restaurantClient from "../../logic/restaurant";

export default function ReviewsTab() {
    const [reviews, setReviews] = React.useState([]);

    const { t } = useTranslation();
    const { restaurant } = useRestaurant();

    const history = useHistory();

    React.useEffect(() => {
        async function getReviews() {
            const reviewData = await restaurantClient.doGetReviews(restaurant.token);

            setReviews(reviewData.data);
        }

        getReviews();
    }, [restaurant.token]);

    const onClickHandler = async () => {
        if (userCanReview(restaurant.token)) {
            history.push("review");
        } else {
            alert("Hai già votato per questo locale. Puoi rivotare nuovamente tra un'ora.");
        }
    };

    return (
        <React.Fragment>
            <button onClick={onClickHandler} className="vote block mx-auto w-[90%] mt-8 mb-2 p-2 text-center text-white bg-mycia-blue rounded-full uppercase">{t("reviews.button_leave_review")}</button>
            <div className="space-y-6">
                <div className="py-4 text-center">
                    <p className="font-semibold">Vuoi contribuire anche tu alla classifica ?</p>
                    <p className="px-2 text-sm">Clicca sul pulsante "LASCIA UNA RECENSIONE" per dire quali sono i piatti che hai apprezzato di più!</p>
                </div>

                {reviews.length > 0 ?
                    <React.Fragment>
                        <div>
                            <div className="w-1/2 ml-3 px-3 py-1 uppercase text-sm rounded-t-lg bg-mycia-green">I 3 piatti best seller</div>
                            <div className="reviews-list px-3 bg-grey-100">
                                {reviews.length > 0 ? reviews[0]?.dishes.map((review, index) =>
                                    <ReviewItem key={index} dish={review.dish} />) :
                                    <p className="no-reviews-message py-6 text-center">Ancora nessun piatto</p>}
                            </div>
                        </div>

                        <div>
                            <div className="w-1/2 ml-3 px-3 py-1 uppercase text-sm rounded-t-lg bg-yellow-400">I 3 piatti più amati</div>
                            <div className="reviews-list px-3 bg-grey-100">
                                {reviews.length > 1 ? reviews[1]?.dishes.map((review, index) =>
                                    <ReviewItem key={index} dish={review.dish} />) :
                                    <p className="no-reviews-message py-6 text-center">Ancora nessun piatto</p>}
                            </div>
                        </div>
                    </React.Fragment> :
                    <div>
                        <p className="text-center font-semibold">Il locale non ha ancora recensioni, sii uno dei primi a farla! Premi il tasto "Fai una recensione".</p>
                    </div>}
            </div>
        </React.Fragment>
    );
}
