import React from 'react';
import { useHistory, useParams } from 'react-router';
import { useCart } from '../../utils/contexts/cart-context';
import { useTranslation } from 'react-i18next';
import { IonIcon } from '@ionic/react';
import Reviews from './Reviews';
import formats from '../../utils/formats';
import { chevronBackOutline } from 'ionicons/icons';
import cartSharingClient from '../../logic/cartSharing';
import forms from '../../utils/forms';

export default function CartSharingGuest() {
    const history = useHistory();
    const { type, id } = useParams();

    const { products, total } = useCart();

    const { t } = useTranslation();

    const urlParams = new URLSearchParams(window.location.search);
    const [shareId] = React.useState(urlParams.get("shareid"));

    const onBackHandler = () => {
        history.goBack();
    };

    function getUserId() {
        const userId = sessionStorage.getItem("user_id");
        if (!userId) {
            const newUserId = forms.generateRandomID("");
            sessionStorage.setItem("user_id", newUserId);
            return newUserId;
        }
        return userId;
    };

    // Guest -> Host
    async function onShareHandler() {
        const name = window.prompt("Come ti chiami ?");
        const payload = {
            resId: id,
            type: type,
            order: [{
                user: name,
                user_id: getUserId(),
                products: products,
            }],
            action: "send active",
            token: shareId,
        };
        await cartSharingClient.doPut(payload);
        history.push(`/${type}/q/${id}/orders/sharesuccess`);
    }

    return (
        <div className="cart-sharing-passive pb-48">
            <div className="flex items-center border-b border-b-grey-200">
                <button type="button" onClick={onBackHandler} className="back-button absolute flex items-center justify-center ml-4 mb-6 p-3 rounded-full shadow-lg bg-white">
                    <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                </button>

                <h2 className="pb-4 w-full text-center font-bold text-2xl">{t("cart.h2_1")}</h2>
            </div>

            <p className="p-4 px-10 text-center">Stai utilizzando il carrello condiviso, termina la condivisione premendo "condividi menu".</p>

            <Reviews products={products} />

            <div className="fixed bottom-0 w-full flex flex-col justify-center mt-8 p-4 bg-white">

                <p className="flex justify-between px-4 py-3"><span>{t("cart.p_1.span_1")}</span> <span className="font-bold">{formats.formatPrice(total)}</span></p>

                <button type="button" onClick={onShareHandler} className="block py-2 rounded-full bg-mycia-green">Condividi il menu</button>

            </div>
        </div>
    );
}
