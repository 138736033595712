import React from 'react';
import parse from 'html-react-parser';
import formats from '../../utils/formats';

export default function SpoilerButton(props) {
    const { content } = props;

    const [summary, setSummary] = React.useState(false);
    const [splitContent, setSplitContent] = React.useState(content);
    const [hideContent, setHideContent] = React.useState();

    React.useEffect(() => {
        try {
            const target = parse(content.replace(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g, formats.replacer));

            if (content.length > 189 && typeof target === "string") {
                setSplitContent(target.slice(0, 189));
                setSplitContent(cont => cont.concat("..."));
                setHideContent(target.slice(189));
                setSummary(true);
            } else {
                setSplitContent(target);
            }
        } catch (e) { console.error(e) }
    }, [content]);


    return (
        <>
            <p className="spoiler-button mt-2">{splitContent}</p>
            {summary ?
                <div>
                    <details>
                        <summary>Continua a leggere</summary>
                        {hideContent}
                    </details>
                </div>
                : null
            }
        </>
    );
}
