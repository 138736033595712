
function fetchFeatures(overrides) {
    let config = {
        "filters-for": process.env.REACT_APP_FILTERS || false,
        "suggestions": process.env.REACT_APP_SUGGESTIONS || false,
        "filters-legend": process.env.REACT_APP_FILTERSLEGEND || false,
        "language-selector": process.env.REACT_APP_LANGUAGESELECTOR || false,
        "versioning": process.env.REACT_APP_VERSIONING || false,
        "subscription": process.env.REACT_APP_SUBSCRIPTION || false,
        "time-discount": process.env.REACT_APP_TIMEDISCOUNT || false,
        "coupon": process.env.REACT_APP_COUPON || false,
        "call-restaurant": process.env.REACT_APP_CALLRESTAURANT || false,
        "cart-sharing": process.env.REACT_APP_CARTSHARING || false,
        "recap": process.env.REACT_APP_RECAP || false,
        "booking-restaurant": process.env.REACT_APP_BOOKINGRESTAURANT || false,
        ...overrides,
    };

    return {
        ...config,

        isEnable(feature) {
            return config[feature];
        }
    };
}

function router(features) {
    return {
        includeFiltersFor() {
            return features.isEnable("filters-for");
        },
        includeSuggestions() {
            return features.isEnable("suggestions");
        },
        includeFiltersLegend() {
            return features.isEnable("filters-legend");
        },
        includeLanguageSelector() {
            return features.isEnable("language-selector");
        },
        includeVersioning() {
            return features.isEnable("versioning");
        },
        includeSubscription() {
            return features.isEnable("subscription");
        },
        includeTimeDiscount() {
            return features.isEnable("time-discount");
        },
        includeCoupon() {
            return features.isEnable("coupon");
        },
        inludeCallRestaurant() {
            return features.isEnable("call-restaurant");
        },
        includeCartSharing() {
            return features.isEnable("cart-sharing");
        },
        includeRecap() {
            return features.isEnable("recap");
        },
        includeBookingRestaurant() {
            return features.isEnable("booking-restaurant");
        },
    };
}

function toggler(overrides) {
    let features = fetchFeatures(overrides);
    return router(features);
}

const features = {
    fetchFeatures,
    router,
    toggler,
};
export default features;
