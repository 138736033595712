import actionsTypes from "../actions/types";

export const defaultState = {
    "customers": 1,
    "customer": [],
    "number": -1,
    "payed": false,
    "products": [],
    "quantity": 0,
    "table": -1,
    "total": 0.0,
    "type": "restaurant",
    "order_note": "",
};

function checkGlassPrice(item) {
    return item.hasOwnProperty("use_glass_price") && item.use_glass_price;
}

function checkDishVariations(item) {
    return item.hasOwnProperty("use_variation");
}

function checkDishAdditions(item) {
    return item.hasOwnProperty("use_additions");
}

function adaptItem(item) {
    if (checkGlassPrice(item)) {
        let obj = Object.assign({}, item);
        obj.name = `${item.name} (Calice)`;
        obj.id = `${item.id}-v`;
        obj.price = item.glass_price;
        obj.original_name = obj.name;
        return obj;
    }
    if (checkDishVariations(item) || checkDishAdditions(item)) {
        let obj = Object.assign({}, item);
        if (checkDishVariations(item)) {
            obj.id = `${item.id}v-${item.use_variation.id}`;
            obj.name = `${item.name} (${item.use_variation.name})`;
            obj.price = item.use_variation.price;
        }
        if (checkDishAdditions(item)) {
            item.use_additions.forEach((addition, i) => {
                if (i === 0) {
                    obj.id = `${obj.id}a-${addition.id}`;
                    obj.name = `${obj.name} (Aggiunte: `;
                }
                else {
                    obj.id = `${obj.id},${addition.id}`;
                }
                obj.name = `${obj.name} ${addition.name}`;
                obj.price = obj.price + addition.price;
                (i !== item.use_additions.length - 1) ? obj.name = `${obj.name}, ` : obj.name = `${obj.name})`;
            });
        }

        obj.original_name = obj.name;
        return obj;
    }

    return item;
}

function reducer(state, action) {
    switch (action.type) {
        case actionsTypes.CART_ADD: {
            let newProduct = adaptItem(action.payload);
            let exists = state.products.find(product => product.id === newProduct.id);

            if (exists) {
                exists.quantity += action.quantity;

                let newQuantity = state.quantity + action.quantity;
                let newTotal = state.total + (newProduct.price * action.quantity);

                return {
                    ...state,
                    "quantity": newQuantity,
                    "total": newTotal,
                };
            } else {
                newProduct.quantity = action.quantity;

                let price = checkGlassPrice(newProduct) ? newProduct.glass_price : newProduct.price;
                let newQuantity = state.quantity + action.quantity;
                let newTotal = state.total + (price * action.quantity);

                return {
                    ...state,
                    "products": [...state.products, newProduct],
                    "quantity": newQuantity,
                    "total": newTotal,
                };
            }
        }
        case actionsTypes.CART_REMOVE: {
            let removedProduct = state.products.find(product => product.id === action.id);
            let newProducts = state.products.filter(product => product.id !== action.id);
            let newTotal = state.total - (removedProduct.price * removedProduct.quantity);
            let newQuantity = state.quantity - removedProduct.quantity;
            return {
                ...state,
                "products": newProducts,
                "quantity": newQuantity,
                "total": newTotal
            };
        }
        case actionsTypes.CART_ADDONE: {
            let product = state.products.find(product => product.id === action.id);
            product.quantity += 1;
            return {
                ...state,
                "quantity": state.quantity + 1,
                "total": state.total + product.price
            };
        }
        case actionsTypes.CART_SUBONE: {
            let product = state.products.find(product => product.id === action.id);

            if (!product) {
                return state;
            }

            if (product.quantity === 1) {
                let newProducts = state.products.filter(product => product.id !== action.id);
                return {
                    ...state,
                    "products": newProducts,
                    "quantity": state.quantity - 1,
                    "total": state.total - product.price
                };
            } else {
                product.quantity -= 1;
                return {
                    ...state,
                    "quantity": state.quantity - 1,
                    "total": state.total - product.price
                };
            }
        }
        case actionsTypes.CART_CLEAN: {
            return defaultState;
        }
        case actionsTypes.CART_TABLE: {
            return {
                ...state,
                "table": action.table
            };
        }
        case actionsTypes.CART_NUMBER: {
            return {
                ...state,
                "number": action.number
            };
        }
        case actionsTypes.CART_TYPE: {
            return {
                ...state,
                "type": action.order_type
            };
        }
        case actionsTypes.CART_ADDNOTE: {
            let product = state.products.find(product => product.id === action.id);
            product.note = action.note;
            return {
                ...state,
            };
        }
        case actionsTypes.CART_ADDORDERNOTE: {
            return {
                ...state,
                "order_note": action.order_note
            };
        }
        case actionsTypes.CART_CUSTOMERS: {
            return {
                ...state,
                customers: action.customers_number,
            };
        }
        case actionsTypes.CART_PAYED: {
            return {
                ...state,
                payed: action.payed,
            };
        }
        case actionsTypes.CART_CUSTOMER: {
            return {
                ...state,
                customer: action.customer,
            };
        }
        case actionsTypes.CART_DELIVERY: {
            return {
                ...state,
                deliveryCost: action.deliveryCost,
            };
        }
        default: {
            throw new Error(`Unhandled action type: ${action.type}`);
        }
    }
}

export default reducer;
