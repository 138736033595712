import React from 'react';
import { IonIcon } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { language } from 'ionicons/icons';
import assets from '../utils/assets';
import locales from '../i18next/locales';

export default function LanguageSelector(props) {
    const { onMenu } = props;
    const { i18n } = useTranslation();

    function onChangeHandler(evt) {
        evt.persist();
        i18n.changeLanguage(evt.target.value);
    };

    function selector() {
        return (
            <div>
                <select name="languages" id="languages" value={i18n.language} onChange={onChangeHandler}>
                    {locales.options.map(([key, label]) => {
                        return <option value={key} key={key}>{label}</option>;
                    })}
                </select>
            </div>
        );
    }

    return (
        <React.Fragment>
            {onMenu === false ?
                <div className="language-selector p-3">
                    <p className="text-center">Select your language</p>
                    <div className="flex flex-wrap items-center pt-2">
                        <div className="flex flex-wrap m-auto items-center">
                            <IonIcon icon={language} className="pr-3"></IonIcon>
                            {selector()}
                        </div>
                    </div>
                </div>
                :
                <div className="flex items-center justify-center">
                    <img src={assets.assetsPath("svg/translation.svg")} alt="language selector" className="w-5 mr-2" />
                    {selector()}
                </div>
            }
        </React.Fragment>
    );
}
