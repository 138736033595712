import React from 'react';
import clsx from 'clsx';

export default function DishAdditions(props) {
    const { product, errorMessages } = props;

    const [changed, setChanged] = React.useState([]);

    React.useEffect(() => {
        product.use_additions = [];
    }, [product]);

    function checkCategorySize(index) {
        let size = product.additions.categories[index].max_picks_allowed;
        let additions = product.use_additions.filter(addition => Number(addition.categoryIndex) === Number(index));

        return additions.length < size;
    }

    function onChangeHandler(e) {
        const categoryIndex = e.target.attributes[2].value;
        const additionIndex = e.target.attributes[3].value;

        if (e.target.checked) {
            if (product.additions.categories[categoryIndex].max_picks_active) {
                if (checkCategorySize(categoryIndex)) {
                    product.use_additions.push({
                        "id": product.additions.categories[categoryIndex].additions[additionIndex].id,
                        "name": product.additions.categories[categoryIndex].additions[additionIndex].name,
                        "price": product.additions.categories[categoryIndex].additions[additionIndex].price,
                        "pos_item_id": product.additions.categories[categoryIndex].additions[additionIndex].pos_id,
                        "categoryIndex": categoryIndex,
                    });
                    setChanged({
                        ...changed, [[categoryIndex, additionIndex]]: e.target.checked
                    });
                }
            } else {
                product.use_additions.push({
                    "id": product.additions.categories[categoryIndex].additions[additionIndex].id,
                    "name": product.additions.categories[categoryIndex].additions[additionIndex].name,
                    "price": product.additions.categories[categoryIndex].additions[additionIndex].price,
                    "pos_item_id": product.additions.categories[categoryIndex].additions[additionIndex].pos_id,
                    "categoryIndex": categoryIndex,
                });
                setChanged({
                    ...changed, [[categoryIndex, additionIndex]]: e.target.checked
                });
            }
        } else {
            product.use_additions = product.use_additions.filter(addedAddition => String(addedAddition.id) !== String(product.additions.categories[categoryIndex].additions[additionIndex].id));
            setChanged({
                ...changed, [[categoryIndex, additionIndex]]: e.target.checked
            });
        }
    }

    return (
        <div className="dish-additions pt-3">
            <div className="p-3">
                <p className="">Che aggiunte vuoi al piatto?</p>
                <div className="py-2">
                    {errorMessages.map((message, i) =>
                        <p className="text-sm text-red-500" key={i}>{message}</p>)}
                </div>

                {product.additions.categories.map((category, index) =>
                    <div key={index}>
                        <p className="pt-4">{category.name} max: {category.max_picks_allowed}</p>
                        {category.additions.map((addition, j) =>
                            <div key={j} className={"flex items-center mt-4 rounded-lg bg-grey-200 border-solid border-l-8" + clsx({ " border-mycia-green": Boolean(changed[[index, j]]), "border-solid border-l-8 border-transparent": !changed[[index, j]] })}>
                                <div className="flex-1">
                                    <input className="hidden" type="checkbox" categoryindex={index} additionindex={j} id={`additions[${index}][${j}]`} onChange={onChangeHandler} />

                                    <label className={clsx(["cursor-pointer select-none block p-4", { "font-bold": changed[[index, j]] }])} htmlFor={`additions[${index}][${j}]`}>{addition.name}</label>

                                </div>
                                {(Number(addition.price) !== 0) && <label className="py-4 px-3" htmlFor="additions">€ {addition.price}</label>}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}
