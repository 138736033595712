import React from 'react';
import filtersReducer, { defaultState } from '../reducers/filters-reducer';
import actionsFilters from '../actions/filters';

const FiltersContext = React.createContext();

function FiltersProvider(props) {
    const [filtered, setFiltered] = React.useState(false);
    const [state, setState] = React.useReducer(filtersReducer, defaultState);

    const { filters, error } = state;

    const setFilters = React.useCallback(filters => {
        setState(actionsFilters.success(filters));
        setFiltered(true);
    }, []);

    const resetFilters = React.useCallback(() => {
        setState(actionsFilters.success({ allergies: null, foods: null, diets: null }));
        setFiltered(false);
    }, []);

    const value = React.useMemo(() => {
        return {
            error,
            filters,
            filtered,
            setFilters,
            resetFilters,
        };
    }, [error, filters, setFilters, resetFilters, filtered]);

    return <FiltersContext.Provider value={value} {...props} />;
}

function useFilters() {
    const context = React.useContext(FiltersContext);
    if (!context) {
        throw new Error("useFilters must be used within a FiltersProvider");
    }
    return context;
}

export { FiltersProvider, useFilters };
