import React from 'react';
import trackData, { trackDataV2 } from '../../logic/analytics';

const TrackerContext = React.createContext();

function TrackerProvider(props) {
    async function trackDataFn(id, token, action, metadata, hasOrders) {

        await trackData(id, action, metadata);
        await trackDataV2(id, token, action, metadata, hasOrders);
    }

    const value = {
        trackDataFn,
    };

    return <TrackerContext.Provider value={value} {...props} />;
}

function useTracker() {
    let context = React.useContext(TrackerContext);
    if (!context) {
        throw new Error("useTracker must be used within a TrackerProvider");
    }
    return context;
}

export {
    TrackerProvider,
    useTracker,
};
