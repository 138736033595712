import React from 'react';
import { IonContent, IonIcon, IonPage } from "@ionic/react";
import { useHistory, useParams } from 'react-router';
import { chevronBackOutline } from 'ionicons/icons';
import SearchDish from '../components/UI/SearchDish';
import restaurantClient from '../logic/restaurant';
import { useRestaurant } from '../utils/contexts/restaurant-context';
import { useTracker } from '../utils/contexts/tracker-context';
import { useUser } from '../utils/contexts/user-context';

export default function ReviewPage() {
    const history = useHistory();
    const { type, id } = useParams();
    const { restaurant } = useRestaurant();
    const { trackDataFn } = useTracker();
    const { user } = useUser();

    const onBackHandler = () => history.push(`/${type}/q/${id}/`);

    const [ordered, setOrdered] = React.useState([]);
    const [preferred, setPreferred] = React.useState([]);

    async function sendReview(event) {
        event.preventDefault();

        let data = Object.fromEntries(new FormData(document.querySelector('form')).entries());

        const res = await restaurantClient.doPostUserReview({ token: restaurant.token, ...data });
        const res1 = await restaurantClient.doPostReview({ token: restaurant.token, ordered: ordered, preferred: preferred });

        if (res || res1) {
            let reviews = JSON.parse(window.localStorage.getItem("__user_has_reviewed__") || "[]");
            reviews.push(restaurant.token);
            window.localStorage.setItem("__user_has_reviewed__", JSON.stringify(reviews));

            await trackDataFn(restaurant.id, restaurant.token, "restaurantrating", {
                vote: data.rating,
                user_token: user ? user.token : null
            }, restaurant.hasOrders);

            const tip = restaurant.tackpay_bucket_id && restaurant.has_tackpay === true && data.rating >= 7 ? `tip=true&tackpay_bucket_id=${restaurant.tackpay_bucket_id}` : "";
            history.push(`/${type}/q/${id}/review/success?${tip}`);
        }
    };

    function removeItem(item, target, setter) {
        const aux = target.filter(el => item !== el);
        setter(aux);
    }

    return (
        <IonPage>
            <IonContent>
                <section id="tip-page" className="relative font-sans">
                    <div className="flex items-center border-b border-b-grey-200">
                        <button type="button" onClick={onBackHandler} className="back-button absolute flex items-center justify-center ml-4 p-3 rounded-full shadow-lg bg-white">
                            <IonIcon slot="icon-only" icon={chevronBackOutline}></IonIcon>
                        </button>
                        <h2 className="py-6 w-full text-center font-bold text-2xl">Valuta il locale</h2>
                    </div>
                </section>

                <form className="space-y-10 p-4" onSubmit={sendReview}>
                    <div className="text-center">
                        <label>Che voto dai al locale?</label>
                        <div className="mt-2 mx-auto p-1 border rounded-md w-26 bg-[#F2EFEF]">
                            <input required type="number" name="rating" min={1} max={10} className="inline pl-2 w-12 text-mycia-red bg-[#F2EFEF]" />
                            <p className="w-3 inline ml-2">/10</p>
                        </div>
                    </div>

                    <div>
                        <label>Scrivi la tua recensione <span className="text-sm text-gray-600">(Opzionale)</span></label>
                        <div className="mt-2 mx-auto p-1 border-none rounded-md bg-[#F2EFEF]">
                            <input type="text" name="text" placeholder="Scrivi un suggerimento" className="inline w-full p-2 bg-[#F2EFEF]" />
                        </div>
                    </div>

                    <div>
                        <label>Che piatti hai ordinato?</label>
                        <SearchDish result={ordered} setResult={setOrdered} />

                        <div className="py-4 space-y-4 z-10">
                            {ordered.map((item, idx) =>
                                <div key={idx} className="relative flex justify-between border-b">
                                    <p className="w-2/3 pl-1">{item.pia_nome}</p>
                                    {/* {item.pia_immagine ?
                                        <img alt="piatto" className="w-1/3 h-20 object-cover p-1 rounded-md" src={item.pia_immagine} /> :
                                        <div className="w-1/3 h-20 object-cover p-1 rounded-md" ></div>} */}
                                    {/* <svg onClick={() => removeItem(item, ordered, setOrdered)} className="absolute -top-2 -right-2 w-5" xmlns="http://www.w3.org/2000/svg" fill="#000000" viewBox="0 0 512 512"><script xmlns="" /><title>ionicons-v5-m</title><path d="M256,48C141.31,48,48,141.31,48,256s93.31,208,208,208,208-93.31,208-208S370.69,48,256,48Zm75.31,260.69a16,16,0,1,1-22.62,22.62L256,278.63l-52.69,52.68a16,16,0,0,1-22.62-22.62L233.37,256l-52.68-52.69a16,16,0,0,1,22.62-22.62L256,233.37l52.69-52.68a16,16,0,0,1,22.62,22.62L278.63,256Z" /><script xmlns="" /></svg> */}
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5" onClick={() => removeItem(item, ordered, setOrdered)} viewBox="0 0 512 512"><path d="M112 112l20 320c.95 18.49 14.4 32 32 32h184c17.67 0 30.87-13.51 32-32l20-320" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M80 112h352" /><path d="M192 112V72h0a23.93 23.93 0 0124-24h80a23.93 23.93 0 0124 24h0v40M256 176v224M184 176l8 224M328 176l-8 224" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><script xmlns="" /></svg>
                                </div>)}
                        </div>
                    </div>

                    <div>
                        <label>Quali sono stati i tuoi piatti preferiti?</label>
                        <SearchDish result={preferred} setResult={setPreferred} />

                        <div className="py-4 space-y-4 z-10">
                            {preferred.map((item, idx) =>
                                <div key={idx} className="relative flex justify-between border-b">
                                    <p className="w-2/3 pl-1">{item.pia_nome}</p>
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5" onClick={() => removeItem(item, ordered, setOrdered)} viewBox="0 0 512 512"><path d="M112 112l20 320c.95 18.49 14.4 32 32 32h184c17.67 0 30.87-13.51 32-32l20-320" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><path stroke="currentColor" stroke-linecap="round" stroke-miterlimit="10" stroke-width="32" d="M80 112h352" /><path d="M192 112V72h0a23.93 23.93 0 0124-24h80a23.93 23.93 0 0124 24h0v40M256 176v224M184 176l8 224M328 176l-8 224" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" /><script xmlns="" /></svg>
                                </div>)}
                        </div>
                    </div>

                    <button type="submit" className="block mx-auto w-full mt-4 mb-2 p-2 text-center text-white bg-mycia-blue rounded-full uppercase">pubblica recensione</button>
                </form>

            </IonContent>
        </IonPage>
    );
}
