import React from 'react';
import formats from '../../utils/formats';

export default function TimetablesOrder(props) {
    const { orders } = props;

    let week = [
        {
            day: "Domenica",
            times: []
        },
        {
            day: "Lunedì",
            times: []
        },
        {
            day: "Martedì",
            times: []
        },
        {
            day: "Mercoledì",
            times: []
        },
        {
            day: "Giovedì",
            times: []
        },
        {
            day: "Venerdì",
            times: []
        },
        {
            day: "Sabato",
            times: []
        },
    ];

    if (orders) {
        for (let days of orders) {
            let aux = formats.getDayOfWeek(days.day);
            for (let index in week) {
                if (Number(index) === aux) {
                    week[aux].times.push({ open: days.open, close: days.close });
                }
            }
        }
    }

    function printHoursOfDay(day) {
        let aux = [];
        week[day].times.map((range, index) =>
            aux.push(
                <td className="border px-4 py-2" key={index}>{range.open} {range.close}</td>
            )
        );
        if (!aux.length) {
            return <td className="border px-4 py-2">Non attivo</td>;
        }
        return aux;
    }

    return (
        <React.Fragment>
            {orders ?
                (<div className="delivery-timetables p-3 text-center">
                    <p className="p-3"> Vedi qua sotto quando è possibile ordinare</p>
                    <table className="mt-6">
                        <tbody>
                            <tr>
                                <td className="border px-4 py-2">Lunedì</td>
                                {printHoursOfDay(1)}
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="border px-4 py-2">Martedì</td>
                                {printHoursOfDay(2)}
                            </tr>
                            <tr>
                                <td className="border px-4 py-2">Mercoledì</td>
                                {printHoursOfDay(3)}
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="border px-4 py-2">Giovedì</td>
                                {printHoursOfDay(4)}
                            </tr>
                            <tr>
                                <td className="border px-4 py-2">Venerdì</td>
                                {printHoursOfDay(5)}
                            </tr>
                            <tr className="bg-gray-100">
                                <td className="border px-4 py-2">Sabato</td>
                                {printHoursOfDay(6)}
                            </tr>
                            <tr>
                                <td className="border px-4 py-2">Domenica</td>
                                {printHoursOfDay(0)}
                            </tr>
                        </tbody>
                    </table>
                </div >) : null
            }
        </React.Fragment>
    );
}
