import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import locales from './locales';
import dl from '../utils/device-language';

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        lng: dl.getApiLanguage(),
        fallbackLng: locales.fallback,
        interpolation: {
            escapeValue: false,
            whitelist: locales.supported,
        },
        react: {
            useSuspense: true
        }
    });

export default i18n;
