import React from 'react';
import groupsClient from '../../logic/groups';

const GroupsContext = React.createContext();

function GroupsProvider(props) {
    let group = process.env.REACT_APP_NAMESPACE;

    const [restaurants, setRestaurants] = React.useState([]);

    const isEmpty = Boolean(0 === restaurants.length);
    React.useLayoutEffect(() => {
        async function fetchData() {
            if (isEmpty && group === "arzignano") {
                try {
                    setRestaurants(await groupsClient.doGetGroupRestaurants(group));
                } catch (err) {
                    console.error(err);
                }
            }
        }
        fetchData();
    }, [group, isEmpty]);

    const value = React.useMemo(() => {
        return {
            restaurants,
        };
    }, [restaurants]);

    return <GroupsContext.Provider value={value} {...props} />;
}

function useGroups() {
    const context = React.useContext(GroupsContext);
    if (!context) {
        throw new Error("useGroups must be used within a GroupsProvider");
    }
    return context;
}

export { GroupsProvider, useGroups };
