import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import checkoutClient from '../logic/checkout';
import { useCart } from '../utils/contexts/cart-context';
import { IonPage, IonContent, IonSpinner } from '@ionic/react';
import { useTracker } from '../utils/contexts/tracker-context';
import { useRestaurant } from '../utils/contexts/restaurant-context';
import stripeClient from '../logic/stripe';

export default function OrderSuccessPage() {
    const { trackDataFn } = useTracker();

    const { type, id } = useParams();

    const { t } = useTranslation();

    const { setPayed } = useCart();

    const { restaurant } = useRestaurant();

    React.useLayoutEffect(() => {
        async function storagePaypal() {
            // let urlParams = new URLSearchParams(window.location.search);
            let storage = window.localStorage.getItem("__mycontactlessmenu_payload__");
            let coupon = window.sessionStorage.getItem("coupon");
            if (storage) {
                let payload = JSON.parse(storage);
                payload.order.payed = true;
                payload.transaction.id = /*urlParams.get("transactionid")*/ "noid";
                payload.transaction.provider = "paypal";

                if (coupon) {
                    coupon = JSON.parse(coupon);
                    if (String(coupon.restaurantAccepted) === String(restaurant.id)) {
                        payload.order.coupon = coupon;
                    }
                } else { payload.order.coupon = {}; }

                let data = checkoutClient.build(payload.restaurant, payload.order, payload.transaction);

                await trackDataFn(restaurant.id, restaurant.token, "sendorder", data, restaurant.has_orders);
                await checkoutClient.doPost(data);
            }
        }
        async function storageSumup() {
            // let urlParams = new URLSearchParams(window.location.search);
            let storage = window.localStorage.getItem("__mycontactlessmenu_payload__");
            let coupon = window.sessionStorage.getItem("coupon");
            if (storage) {
                let payload = JSON.parse(storage);
                payload.order.payed = true;
                payload.transaction.id = /*urlParams.get("transactionid")*/ "noid";
                payload.transaction.provider = "sumup";

                if (coupon) {
                    coupon = JSON.parse(coupon);
                    if (String(coupon.restaurantAccepted) === String(restaurant.id)) {
                        payload.order.coupon = coupon;
                    }
                } else { payload.order.coupon = {}; }

                let data = checkoutClient.build(payload.restaurant, payload.order, payload.transaction);

                await trackDataFn(restaurant.id, restaurant.token, "sendorder", data, restaurant.has_orders);
                await checkoutClient.doPost(data);
            }
        }
        async function storageCash() {
            let storage = window.localStorage.getItem("__mycontactlessmenu_payload__");
            let coupon = window.sessionStorage.getItem("coupon");
            if (storage) {
                let payload = JSON.parse(storage);

                if (coupon) {
                    coupon = JSON.parse(coupon);
                    if (String(coupon.restaurantAccepted) === String(restaurant.id)) {
                        payload.order.coupon = coupon;
                    }
                } else { payload.order.coupon = {}; }

                let data = checkoutClient.build(payload.restaurant, payload.order, payload.transaction);

                await trackDataFn(restaurant.id, restaurant.token, "sendorder", data, restaurant.has_orders);
                // client.doSendMessage(payload);
                await checkoutClient.doPost(data);
            }
        }
        async function storageStripe() {
            let urlParams = new URLSearchParams(window.location.search);
            let storage = window.localStorage.getItem("__mycontactlessmenu_payload__");
            let coupon = window.sessionStorage.getItem("coupon");
            if (storage) {
                let payload = JSON.parse(storage);
                payload.order.payed = true;
                payload.transaction.id = urlParams.get("transactionid");
                payload.transaction.provider = "stripe";

                if (coupon) {
                    coupon = JSON.parse(coupon);
                    if (String(coupon.restaurantAccepted) === String(restaurant.id)) {
                        payload.order.coupon = coupon;
                    }
                } else { payload.order.coupon = {}; }

                let data = checkoutClient.build(payload.restaurant, payload.order, payload.transaction);

                await trackDataFn(restaurant.id, restaurant.token, "sendorder", data, restaurant.has_orders);

                var sripePayload = {
                    type: payload.order.type,
                    risId: payload.restaurant.id,
                    stripePaymentId: payload.transaction.id,
                    orderId: data.order.id,
                };

                await stripeClient.doPostTransaction(sripePayload);

                await checkoutClient.doPost(data);
            }
        }
        async function sendToDashboard() {
            let urlParams = new URLSearchParams(window.location.search);

            if (urlParams.has("provider")) {
                let provider = urlParams.get("provider");

                if ("paypal" === provider) {
                    setPayed(true);
                    storagePaypal();
                }
                if ("cash" === provider) {
                    storageCash();
                }
                if ("sumup" === provider) {
                    setPayed(true);
                    storageSumup();
                }
                if ("stripe" === provider) {
                    setPayed(true);
                    storageStripe();
                }
            }
            window.localStorage.removeItem("__mycontactlessmenu_payload__");
            let coupon = window.sessionStorage.getItem("coupon");
            if (coupon) {
                window.sessionStorage.removeItem("coupon");
            }
        }

        sendToDashboard();
    }, [restaurant.has_orders, restaurant.id, restaurant.token, setPayed, trackDataFn]);

    return (
        <IonPage>
            <IonContent>
                <section id="success-page" className="relative pt-8 pb-32 font-sans text-center">
                    <div className="px-8">
                        {String(restaurant.id) === "9288" ?
                            <p>Gentile cliente grazie per aver ordinato da tao, nei prossimi minuti la sua richiesta verrà accettata o rifiutata, la preghiamo di attendere fino al prossimo messaggio.</p> :
                            <div>
                                <h2 className="font-bold text-4xl">Attesa conferma</h2>
                                <p className="mt-8">La tua richiesta è stata inviata! Riceverai un SMS sullo stato dell'ordine.</p>
                            </div>}
                    </div>
                    <img src="/assets/images/checkout_success.png" className="w-1/2 m-auto pt-12" alt="checkout success" />

                    <div className="fixed bottom-0 w-full flex flex-col justify-center mt-8 px-4 py-6 bg-white">
                        <a href={`/${type}/q/${id}/menu`} className="block py-2 rounded-full bg-mycia-green">{t("successpage.button_1")}</a>
                    </div>
                </section>
            </IonContent>
        </IonPage>
    );
}
