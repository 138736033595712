import { RESTAURANT } from './types';

function error(error) {
    return {
        "type": RESTAURANT.RESTAURANT_ERROR,
        "error": new Error(error),
    };
}

function fetching(restaurantId) {
    return {
        "type": RESTAURANT.RESTAURANT_FETCHING,
        "id": restaurantId,
    };
}

function success(restaurant) {
    return {
        "type": RESTAURANT.RESTAURANT_SUCCESS,
        "restaurant": restaurant,
    };
}

function searching(search) {
    return {
        "type": RESTAURANT.RESTAURANT_SEARCHING,
        "search": search,
    };
}

const actionsRestaurant = {
    error,
    fetching,
    success,
    searching,
};
export default actionsRestaurant;
