import React from "react";
import { useTranslation } from "react-i18next";
import { IonIcon } from "@ionic/react";
import { useParams } from "react-router";
import Bucket from "./cart/Bucket";
import DeliveryPopupModalTrigger from "./modals/DeliveryPopupModalTrigger";
import LanguageSelector from "./LanguageSelector";
import assets from "../utils/assets";
import features from "../utils/features";
import formats from "../utils/formats";
import sharer from "../utils/sharer";
import { people, shareOutline, personOutline } from "ionicons/icons";
import TimeDiscountModalTrigger from "./modals/TimeDiscountModalTrigger";
import TabRouter from "./UI/tabs/TabsRouter";
import SpoilerButton from "./UI/SpoilerButton";
import SubscriptionModalTrigger from "./modals/SubscriptionModalTrigger";
import CiaLoginModalTrigger from "./modals/CiaLoginModalTrigger";
import CallRestaurantModalTrigger from "./modals/CallRestaurantModalTrigger";
import BucketHeader from "./cart/BucketHeader";
import LoginSuggestion from "./UI/LoginSuggestion";
import { Link } from "react-router-dom";
import { useUser } from "../utils/contexts/user-context";
import FilterToggle from "./UI/FilterToggle";
import FilterModalTrigger from "./modals/FilterModalTrigger";
import TackpayFollowUpModal from "./modals/TackpayFollowUpModal";

export default function List(props) {
    const { restaurant } = props;

    const [showSubscriptionModal, setShowSubscriptionModal] = React.useState(true);
    const [showModal, setShowModal] = React.useState(false);
    const [showCiaLoginModal, setShowCiaLoginModal] = React.useState(false);
    const [showTackpayInfoModal, setShowTackpayInfoModal] = React.useState(false);

    const { user } = useUser();
    const { type, id } = useParams();
    const { t, i18n } = useTranslation();

    function tackpayRouting() {
        if (restaurant.has_tackpay === false) {
            setShowTackpayInfoModal(true);
        } else {
            const success_url = `${window.location.origin}/${type}/q/${id}/tip/success`;
            const tackPayUrl = `${process.env.REACT_APP_TACKPAY}/checkout/${restaurant.tackpay_bucket_id}?&success_url=${success_url}&error_url=error_url`;

            window.open(tackPayUrl, '_blank');
        }
    }

    React.useLayoutEffect(() => {
        let storage = window.localStorage.getItem("has_subscription");
        let obj = [{ id: "" }];
        if (storage) {
            obj = JSON.parse(storage);
        }
        if (obj.filter(a => String(a.id) === String(id)).length > 0) {
            setShowSubscriptionModal(false);
        }
    }, [id]);

    const defaultTab = React.useMemo(() => {
        if (["mycia", "test"].includes(process.env.REACT_APP_NAMESPACE)) {
            return restaurant.tasting_menu.length === 0 ? 1 : restaurant.tasting_menu.length;
        } else {
            if (restaurant.beverages.length > 0) {
                return restaurant.tasting_menu.length + (restaurant.courses.length > 0 ? 2 : 1);
            } else if (restaurant.courses.length > 0) {
                return restaurant.tasting_menu.length + 1;
            } else {
                return restaurant.tasting_menu.length;
            }
        }
    }, [restaurant]);

    return (
        <div className="relative">
            <BucketHeader type={type} id={id} />

            <div>
                {"mycia" === process.env.REACT_APP_NAMESPACE ?
                    <img src={restaurant.image_path} alt={t("menu.img_1")} className="w-full" /> :
                    <div className="restaurant-image w-full p-4 z-20">
                        <img src={assets.assetsLogoPath(restaurant.id)} alt="test" />
                    </div>}
            </div>

            <div className="list relative -mt-4 py-2 rounded-t-lg bg-white">
                <header className="px-4 pb-[1px] border-b border-b-gray-200">
                    <div className="flex justify-between items-center">
                        <div className="pr-2">
                            <h1 className="font-medium text-3xl">{restaurant.name}</h1>
                        </div>
                        <div className="flex items-center space-x-2">
                            {restaurant.tackpay_bucket_id &&
                                <button type="button" target="_blank" rel="noreferrer noopener" onClick={() => tackpayRouting()} className="w-10 h-10 cursor-pointer">
                                    <img src="/assets/images/tip.png" className="w-10 h-10 rounded-full bg-[#93E6A2]" alt="tip icon" />
                                </button>}
                            {features.toggler().includeLanguageSelector() ?
                                <LanguageSelector onMenu={true} /> :
                                <div>
                                    <button onClick={() => (sharer.shareContent(`${type}/q/${id}`, restaurant.name, t("menu.button_1")))} className="button-native share w-10 h-10 flex items-center justify-center rounded-full shadow-lg text-black">
                                        <IonIcon icon={shareOutline} size="medium" className="mb-1 text-xl"></IonIcon>
                                    </button>
                                </div>}
                            {restaurant.has_phonecall ? restaurant.phone_number && type !== "r" &&
                                <div className="mx-2">
                                    <CallRestaurantModalTrigger restaurant={restaurant} />
                                </div>
                                :
                                null
                            }
                            {restaurant.has_newsletter ?
                                showSubscriptionModal ?
                                    <button className="button-native subscription w-10 h-10 flex items-center justify-center rounded-full shadow-lg text-black" onClick={() => setShowModal(true)}>
                                        <IonIcon icon={people} />
                                    </button>
                                    :
                                    null
                                :
                                null
                            }
                            {restaurant.has_login && "it" === i18n.language ?
                                user ?
                                    <Link to={`/profile?type=${type}&token=${id}`} className="button-native subscription w-10 h-10 flex items-center justify-center rounded-full shadow-lg text-white bg-mycia-red">
                                        <IonIcon icon={personOutline} />
                                    </Link> :
                                    <a href={formats.loginUrl(type, id)}
                                        className="button-native subscription w-10 h-10 flex items-center justify-center rounded-full shadow-lg text-white bg-mycia-red">
                                        <IonIcon icon={personOutline} />
                                    </a> :
                                null}
                        </div>
                    </div>

                    <SpoilerButton content={restaurant.menu_description} />

                    {formats.shouldDisplayRestaurantCoverCharge(restaurant, type) ?
                        <p className="mt-2 font-semibold">{t("menu.p_1")}: {formats.formatPrice(restaurant.cover_charge)}</p> : null}

                    {restaurant.has_login && "it" === i18n.language ?
                        user ?
                            <FilterToggle type={type} id={id} /> :
                            <LoginSuggestion type={type} id={id} /> :
                        null}

                    {restaurant.has_filter ? <FilterModalTrigger></FilterModalTrigger> : null}
                </header>

                <TabRouter restaurant={restaurant} hasOrders={true} defaultTab={defaultTab} />

                <Bucket />

                {/* *** Various popup *** */}

                {restaurant.has_newsletter ? showSubscriptionModal ? <SubscriptionModalTrigger showModal={showModal} setShowModal={setShowModal} /> : <DeliveryPopupModalTrigger /> : <DeliveryPopupModalTrigger />}
                {features.toggler().includeTimeDiscount() ? <TimeDiscountModalTrigger /> : null}
                {restaurant.has_login && "it" === i18n.language ? !user ? <CiaLoginModalTrigger showModal={showCiaLoginModal} setShowModal={setShowCiaLoginModal} /> : null : null}
                <TackpayFollowUpModal showModal={showTackpayInfoModal} setShowModal={setShowTackpayInfoModal} />
            </div>
        </div>
    );
}
