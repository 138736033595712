import React from 'react';
import { IonModal, IonIcon } from '@ionic/react';
import { close } from 'ionicons/icons';
import './ViviInfoModalStyle.css';

export default function ViviInfoModal({ token }) {
    const [showModal, setShowModal] = React.useState(false);

    React.useLayoutEffect(() => {
        setTimeout(() => setShowModal(true), 1500);
    }, [setShowModal]);

    function onCloseHandler() {
        setShowModal(false)
    }

    const viviLocations = [
        { token: "56HW2Xyf4", name: "VIVI PIAZZA VENEZIA" },
        { token: "sBPiWrAwy", name: "VIVI PIAZZA NAVONA" },
        { token: "kP0M9ZYH5", name: "VIVI VILLA PAMPHILI" },
        { token: "uDmsbbYYS", name: "VIVI GALLERIA BORGHESE" },
        { token: "3WgKD9roy", name: "VIVI LE SERRE" }
    ];

    return (
        <IonModal cssClass="vivi-info-modal-trigger" mode="md" isOpen={showModal} onDidDismiss={onCloseHandler}>
            <div className="overflow-auto text-black p-6 relative">
                <button type="button" onClick={onCloseHandler} className="z-10 absolute top-0 right-0 flex items-center justify-center mt-4 mr-4">
                    <IonIcon className="w-6 h-6" slot="icon-only" icon={close}></IonIcon>
                </button>

                <div className="mt-8 mb-4 text-center">
                    <p>
                        Benvenuti,
                        Le informazioni circa la presenza di sostanze o di prodotti che provocano allergie o intolleranze sono disponibili rivolgendosi al personale in servizio.
                        Per qualsiasi informazione su sostanze e allergeni è possibile consultare l’apposita documentazione che verrà fornita a richiesta dal personale in servizio.
                        Grazie per l’attenzione,
                    </p>
                    <p className="pt-6">{viviLocations.find(location => location.token === token)?.name}</p>
                </div>
            </div>
        </IonModal>
    );
}
