import React from "react";
import parse from "html-react-parser";
import TastingList from "../../list/TastingList";
import TastingMenu from "../../menu/TastingMenu";

export default function TastingMenuContent(props) {
    const { menu, hasOrders, add } = props;

    return (
        <div>
            <p className="px-4 text-base">{parse(menu.description || menu.description_raw)}</p>

            {menu.courses.map((course, index) => hasOrders ?
                <TastingList course={course} index={index} key={`tasting-course-${index}`} tastingMenu={menu}></TastingList> :
                <TastingMenu course={course} index={index} key={`tasting-course-${index}`} tastingMenu={menu}></TastingMenu>)}

            <p className="text-center py-4 font-semibold">Prezzo menù completo: {menu.price}€</p>

            {hasOrders && menu.courses.length > 0 ?
                <div className="cta-tasting-menu p-4">
                    <button type="button" onClick={() => add(menu, 1)} className="w-full py-2 px-4 rounded-full bg-mycia-green">Ordina il menu</button>
                </div> : null}
        </div>
    );
}
