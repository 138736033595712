import React from "react";
import Tabs from "./Tabs";

export default function TabRouter(props) {
    const { hasOrders, defaultTab } = props;

    switch (process.env.REACT_APP_NAMESPACE) {
        case "mycia": case "aperolspritz": case "campari": case "camparisoda": case "averna": case "wild": case "espolon": case "bulldog": case "braulio": case "crodinoxl": case "redbull": case "sanpellegrino":
            return <Tabs hasOrders={hasOrders} defaultTab={defaultTab}></Tabs>
        default:
            return <Tabs hasOrders={hasOrders} defaultTab={defaultTab}></Tabs>
    }
}
