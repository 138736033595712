import React from 'react';
import { IonContent, IonPage } from "@ionic/react";
import AllergensModalTrigger from '../components/modals/AllergensModalTrigger';
import features from '../utils/features';
import Fork from '../components/Fork';
import PoweredBy from '../components/UI/PoweredBy';
import RestaurantLoader from '../components/containers/RestaurantLoader';
import theme from '../utils/theme';
import { useRestaurant } from '../utils/contexts/restaurant-context';

export default function MenuPage(props) {
    const [palette, setPalette] = React.useState("");

    const { restaurant } = useRestaurant();

    React.useLayoutEffect(() => {
        if (restaurant) {
            if (restaurant.custom_webapp_theme) {
                const styleNode = document.createElement("style");
                styleNode.innerHTML = atob(restaurant.custom_webapp_theme);
                document.querySelector("head").append(styleNode);
            } else if (!process.env.REACT_APP_THEME) {
                theme.getTheme(restaurant.id, setPalette);
            } else {
                setPalette(process.env.REACT_APP_THEME);
            }
        }
    }, [restaurant]);

    return (
        <IonPage>
            <IonContent style={theme.getThemeIonComponents(palette).menupage}>
                <section id="menu-page" className="relative font-sans">
                    <RestaurantLoader component={Fork} {...props} />

                    <AllergensModalTrigger />

                    <PoweredBy />
                </section>
                {features.toggler().includeVersioning() && <p className="text-center text-sm">v.2.5.19</p>}
            </IonContent>
        </IonPage>
    );
}
