import React from "react";
import { useParams } from "react-router";
import { useRestaurant } from "../../utils/contexts/restaurant-context";
import { useTracker } from "../../utils/contexts/tracker-context";
import formats from "../../utils/formats";
import assets from "../../utils/assets";
import PreferredButton from "../UI/PreferredButton";
import Details from "../dish/Details";
import Cta from "../cart/Cta";
import SuggestedDrink from "./SuggestedDrink";

export default function Product(props) {
    const { product, isTastingMenu } = props;

    const { type } = useParams();
    const { trackDataFn } = useTracker();
    const { restaurant } = useRestaurant();

    const [showDishModal, setShowDishModal] = React.useState(false);

    const onDetailsOpenHandler = async () => {
        await trackDataFn(restaurant.id, restaurant.token, "dishview", {
            "type": type,
            "dish": product.id,
            "name": product.name,
            "price": product.price
        }, restaurant.has_orders);

        setShowDishModal(true);
    };

    return (
        <div className="product p-2">
            {product.special ?
                <div className="w-1/2 px-3 rounded-t-lg text-sm text-center bg-yellow-500">PIATTO CONSIGLIATO</div> :
                null}

            <div className="product-content flex flex-col shadow rounded-lg bg-grey-100">
                <div className="flex">
                    <div className="flex-1 py-2 px-4">
                        <div className="h-full flex flex-col justify-between">
                            <div>
                                <p className="font-semibold">{formats.capitalize(product.name)}</p>

                                <Details dish={product} showDishModal={showDishModal} setShowDishModal={setShowDishModal} onDetailsOpenHandler={onDetailsOpenHandler} />
                            </div>

                            {formats.shouldDisplayFilterIcon(product) ?
                                <div className="flex items-center gap-2">
                                    <img className="inline-block w-6 mr-2" src={assets.assetsPath(product.icon)} alt="" />
                                    <span className="uppercase text-sm">{formats.chooseIconText(product.icon)}</span>
                                </div> : null}
                        </div>
                    </div>

                    <div className="flex flex-col items-end py-2 pr-4 text-base">
                        <PreferredButton item={product} />

                        {!isTastingMenu && formats.cleanPrice(product.price) !== "" ?
                            <p className="mt-2 font-bold">{formats.formatPrice(product.price)}</p> : null}

                        {(typeof product.image_path === "string" && product.image_path !== "") ?
                            <img src={product.image_path} className="object-cover w-26 h-26 my-3 rounded-lg border border-grey-300" onClick={onDetailsOpenHandler} alt="product" /> :
                            null}
                    </div>
                </div>

                {(product.suggested_drink !== "") ?
                    <SuggestedDrink product={product} /> : null}

                {(!isTastingMenu && formats.shouldDisplayCta(type, restaurant.orders)) ? <Cta product={product} /> : null}
            </div>
        </div >
    );
}
